import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { listAll, ref, getDownloadURL, getStorage } from "firebase/storage";

/**
 * Downloads each inner folder as a separate ZIP file.
 * @param {Function} setIsDownloading - Function to set the downloading state.
 */
const downloadFoldersAsSeparateZips = async (setIsDownloading) => {
  const storage = getStorage();
  const folderRef = ref(storage, "pte-a");

  try {
    setIsDownloading(true); // Indicate the start of downloading

    const root = await listAll(folderRef);

    // Process each inner folder
    for (const outerFolder of root.prefixes) {
      const innerFolderRelativePath = outerFolder._location.path_;
      const innerFolderRef = ref(storage, innerFolderRelativePath);

      const innerFolderItems = await listAll(innerFolderRef);
      const jszip = new JSZip();

      const filesPromises = innerFolderItems.items.map(async (file) => {
        const fileRelativePath = file._location.path_;
        const fileName = fileRelativePath.split("/").pop();

        const fileRef = ref(storage, fileRelativePath);
        const fileUrl = await getDownloadURL(fileRef);
        const fileBlob = await fetch(fileUrl).then((res) => res.blob());

        jszip.file(fileName, fileBlob); // Add file to the ZIP
      });

      await Promise.all(filesPromises);

      // Generate and save ZIP file for the current folder
      const folderName = outerFolder.name; // Use the folder's name for the ZIP file
      const zipBlob = await jszip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `${folderName}.zip`);
    }
  } catch (error) {
    console.error("Error downloading ZIP files:", error);
  } finally {
    setIsDownloading(false); // Reset the downloading state
  }
};

/**
 * A React button component to trigger folder download.
 * @param {Object} props - The component props.
 * @returns {JSX.Element}
 */
const DownloadButton = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (props.signedIn) {
      downloadFoldersAsSeparateZips(setIsDownloading);
    }
  }, [props.signedIn]);

  return (
    <button
      className={`btn ${props.variant} ${props.size} ${props.classLabel}`}
      onClick={() => {
        if (props.signedIn) {
          console.log("1:Signed in");
        } else {
          console.log("2:Not signed in");
        }
      }}
      disabled={props.disabled || isDownloading}
    >
      {isDownloading ? "Downloading..." : props.text}
    </button>
  );
};

export default DownloadButton;
