import {processAudioChunk} from "./processAudioChunk";


export const analyzeStreamWithLangDetection = (stream) => {
    console.log("In analyzeStreamWithLangDetection");
    return new Promise((resolve) => {
      // Clone the MediaStream to prevent interference with the parent MediaRecorder
      const clonedStream = stream.clone();
      const mediaRecorder = new MediaRecorder(clonedStream, { mimeType: 'audio/webm' });
  
      const audioChunks = [];
  
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };
  
      mediaRecorder.onstop = async () => {
        // Create an audioBlob from the collected chunks
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
  
        // Pass the audioBlob to processAudioChunk for English detection
        const isEnglishDetected = await processAudioChunk(audioBlob);
  
        // Resolve the promise with the detection result
        resolve(isEnglishDetected);
      };
  
      // Start recording and set an interval to stop recording periodically
      mediaRecorder.start();
  
      const interval = setInterval(() => {
        if (mediaRecorder.state === "recording") {
          mediaRecorder.stop(); // Stop the recorder to finalize the blob
        }
      }, 5000); // Adjust the interval as needed
  
      // Stop the clone when the parent stream ends
      clonedStream.getTracks().forEach((track) => {
        track.onended = () => {
          clearInterval(interval);
          if (mediaRecorder.state === "recording") {
            mediaRecorder.stop();
          }
        };
      });
    });
  };
  
// export const analyzeStreamWithLangDetection = (stream, audioChunksRef) => {
//     console.log("In analyzeStreamWithLangDetection");
//     console.log("audioChunksRef:", audioChunksRef, "Length:", audioChunksRef.current.length);
//     return new Promise((resolve) => {
//       const audioContext = new AudioContext();
//       const mediaStreamSource = audioContext.createMediaStreamSource(stream);
//       const analyser = audioContext.createAnalyser();
//       analyser.fftSize = 2048;
//       mediaStreamSource.connect(analyser);
  
//       // Interval-based analysis
//       const interval = setInterval(async () => {
//         if (audioChunksRef.current.length > 0) {
//           // Create an audioBlob from the accumulated audio chunks
//           const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
//           audioChunksRef.current = []; // Clear processed chunks after creating the blob
  
//           // Pass the audioBlob to processAudioChunk
//           const isEnglishDetected = await processAudioChunk(audioBlob);
  
//           if (isEnglishDetected) {
//             clearInterval(interval); // Stop interval
//             resolve(true); // Resolve with true if English is detected
//           }
//         }
//       }, 1000); // Process every 1 second
  
//       // Handle stream end
//       stream.oninactive = () => {
//         clearInterval(interval);
//         resolve(false); // Resolve with false if the stream ends without detecting English
//       };
//     });
//   };
  
  