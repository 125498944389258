import React, { useState, useRef, useEffect } from "react";
import {
  processPassageAudio,
  processAudio,
  processText,
  playAudio,
  stopSystemAudioPlayback,
  processChatAudio,
  processOpinionAudio,
  processAdviceAudio,
} from "../../../../utils/OpenAi";
import useCheckSilence from "../../../../utils/hooks/useCheckSilence";
import { detectLanguageFromAudio } from "../../../../utils/detectLanguageFromAudio";
// import { analyzeRealTimeAudioWithWebSpeechAPI } from "../../../../utils/analyzeRealTimeAudioWithWebSpeechAPI";
// import { set } from "react-hook-form";

/**
 * Higher-Order Component (HOC) to provide recording functionality to a wrapped component.
 * @param {React.Component} WrappedComponent - The component to wrap with recording functionality.
 * @returns {React.Component} - The wrapped component with added recording functionality.
 */
const withRecording = (WrappedComponent) => {
  return (props) => {
    const wordDetectedRef = useRef(null);

    const [isRecording, setIsRecording] = useState(false);
    const [dataArray, setDataArray] = useState(null);
    const [analyser, setAnalyser] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [audioHistory, setAudioHistory] = useState([]);
    const [passageData, setPassageData] = useState(null);
    const [activityCountdownTimerComplete, setActivityCountdownTimerComplete] =
      useState(false);
    const [wordDetected, setWordDetected] = useState(wordDetectedRef.current);

    const initialQuestionAskedRef = useRef(false);
    const {
      silentAfterTalking4AtLeast5Seconds,
      isSilentForFiveSeconds,
      isTalkingForFiveSeconds,
      startedTalking,
      toggle,
      type,
      rms,
      reset,
    } = useCheckSilence(
      analyser,
      isRecording,
      dataArray,
      activityCountdownTimerComplete
    );
    const audioChunks = useRef([]);
    const mediaRecorder = useRef(null);
    const audioContext = useRef(null);
    const animationFrameId = useRef(null);
    const isSilentRef = useRef(isSilentForFiveSeconds);

    /********************************
     * Setup microphone for recording
     * and calls process Audio
     * @returns {Promise<void>}
     *********************************/
    const startRecording = async (
      isSystemAudioResponseRequested,
      initialSystemQuestion,
      comType,
      systemPrompt,
      currentActivityType,
      chatTurnCounter,
      nextBtnClicked,
      questions
    ) => {
      const chatInteraction =
        comType === "spoken" &&
        systemPrompt &&
        currentActivityType === "chat" &&
        typeof chatTurnCounter === "number" &&
        typeof initialSystemQuestion === "string";

      console.log(
        "chatInteraction:",
        chatInteraction,
        "ComType:",
        comType,
        "ActivityType:",
        currentActivityType,
        "nextBtnClicked:",
        nextBtnClicked,
        "questions:",
        questions
      );

      if (chatInteraction) {
        console.log(
          "STARTING RECORDING: systemPrompt:",
          systemPrompt,
          "ComType:",
          comType,
          "CurrentActivityType:",
          currentActivityType,
          "chatTurnCounter:",
          chatTurnCounter,
          "initialSystemQuestion:",
          initialSystemQuestion
        );
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        audioContext.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        mediaRecorder.current = new MediaRecorder(stream);
        /*****************************
         * Step1: Start Audio Recording:
         * When MediaRecorder has started
         * Everytime data is available you will
         * add it to your chunksarray
         *****************************/

        mediaRecorder.current.ondataavailable = (event) => {
          /************************************************************
           * IMPORTANT: MEDIA_RECORDER EXIT CONDITION:
           * In order to stop processing jump data from a
           * previous scenario, we must delete the previous
           * recording audio chunks, when the next button is clicked.
           * (1) Identify next button is clicked
           * (2) Delete previous audio chunks
           *************************************************************/
          // (1) Identify next button click
          if (nextBtnClicked.current) {
            console.log(
              "Next button was clicked! submitBtnClicked:1",
              nextBtnClicked.current
            );
            wordDetectedRef.current = null;
            setChatHistory([]);
            setAudioHistory([]);

            // (2) Delete previous audio chunks
            audioChunks.current = []; // Clear audio chunks
            reset();
            return; // Exit if next button is clicked
          }
          /***************************************************************/
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
          }
        };
        /*****************************************************************
         * Step 5: Create the arguments analyser, isRecording, dataArray, to be used by
         * the useCheckSilence hook, to filter out silence and trim audio
         *****************************************************************/
        const audioInput = audioContext.current.createMediaStreamSource(stream);
        const analyserNode = audioContext.current.createAnalyser();
        analyserNode.fftSize = 2048; // Ensure this is set
        audioInput.connect(analyserNode);

        setAnalyser(analyserNode);
        const newDataArray = new Uint8Array(analyserNode.fftSize);
        setDataArray(newDataArray);

        mediaRecorder.current.start();

        console.log(
          "Recording started: nextBtnClicked:",
          nextBtnClicked.current
        );
        let analysisPromise; // Declare a variable to hold the analysis promise

        if (!nextBtnClicked.current) {
          /***********************************
           * This is required to get it working
           ************************************/
          analysisPromise = true;
          wordDetectedRef.current = analysisPromise;
          console.log("Is speaking detected:", wordDetectedRef.current);
          setWordDetected(analysisPromise);
          /******************************/
          /*****DEPRECATEDSTART******/
          // analyzeRealTimeAudioWithWebSpeechAPI(stream, audioContext.current)
          //   .then((result) => {
          //     // if (!nextBtnClicked.current) {
          //     wordDetectedRef.current = result;
          //     console.log("Is speaking detected:", wordDetectedRef.current);

          //     // } else {
          //     //   wordDetectedRef.current = null; // Stop further processing if next button clicked
          //     // }
          //     return wordDetectedRef.current;
          //   })
          //   .then((result) => {
          //     console.log("Setting wordDetected Result:", result);
          //     setWordDetected(result);
          //   })
          //   .catch((error) => {
          //     console.error("Error during speech analysis:", error);
          //   });
          /*****DEPRECATEDSTART******/
        } //if
        /*************************************************
         * Step 2: Stop Audio Recording: Create audioBlob
         * When MediaRecorder has been stopped
         * trim Audio Sample and send for processing
         **************************************************/
        mediaRecorder.current.onstop = async () => {
          if (nextBtnClicked.current) {
            wordDetectedRef.current = null;
            return; // Exit if next button is clicked
          }

          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          console.log(
            "audioChunks:",
            audioChunks,
            "currentActivityType:",
            currentActivityType
          );
          /**********************************************************
           * THIS IS WHERE YOU SHOULD CHECK IF THE AUDIO IS ENGLISH
           * USING LANGID, IF NOT SET WORDDETECTED TO NULL
           **********************************************************/
          console.log("AUDIOBLOB.SIZE:", audioBlob.size);
          // Example usage
          if (audioBlob.size > 0) {
            //WhisperAI Failed to detect language
            // let isEnglishBlob;
            // detectLanguageWithWhisper(audioBlob2)
            // .then((language) => {
            //   console.log(`Detected Language: ${language}`);
            //   isEnglishBlob = language;
            // })
            // .catch(console.error);
            const isEnglishBlob = await detectLanguageFromAudio(audioBlob);
            try {
              if (isEnglishBlob) {
                console.log("HasWord:", isEnglishBlob);
                wordDetectedRef.current = isEnglishBlob;
                setWordDetected(isEnglishBlob);
              }
            //   else
            //     wordDetectedRef.current = null;
            } 
            catch (error) {
              console.error("Error during transcription or checking for English words:", error);
            }
          }
          /*******************************************/
          audioChunks.current = [];
          /************************
           * Play AudioBlob
           ************************/
          // playAudioBlob(audioBlob);

          /*************************************
           * Step 4: Process audio when noise is
           * detected and transcribe data
           *************************************
           * IMPORTANT: Checks if audio is a word
           * in Chat activityType, but not in Advice
           * activityType
           **************************************/
          if (!isSilentRef.current && audioBlob.size > 0) {
            let dataObject = null;
            /***************************************
             * PROCESSAUDIO:  If audio has been
             * collected then send to the processAudio
             * to trim and remove silent portions after
             * the "I'm done" button clicked or page
             * transitioned automatically.
             ***************************************/
            // if (chatInteraction) {
            if (currentActivityType === "chat") {
              console.log("Processing ChatAudio");
              dataObject = await processChatAudio(
                isSystemAudioResponseRequested,
                initialSystemQuestion,
                systemPrompt,
                chatTurnCounter,
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                audioHistory,
                setAudioHistory,
                wordDetectedRef.current,
                initialQuestionAskedRef.current,
                nextBtnClicked,
                wordDetectedRef
              );
            } else if (currentActivityType === "opinion") {
              console.log("Processing OpinionAudio:", currentActivityType);
              dataObject = await processOpinionAudio(
                isSystemAudioResponseRequested,
                initialSystemQuestion,
                systemPrompt,
                chatTurnCounter,
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                audioHistory,
                setAudioHistory,
                wordDetectedRef.current,
                initialQuestionAskedRef.current,
                nextBtnClicked,
                wordDetectedRef,
                questions
              );
            } else if (currentActivityType === "passage") {
              dataObject = await processPassageAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                wordDetectedRef.current,
                currentActivityType
              );
            } else if (currentActivityType === "advice") {
              dataObject = await processPassageAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                wordDetectedRef.current,
                currentActivityType
              );
            } else {
              dataObject = await processAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                false,
                false,
                wordDetectedRef.current
              );
            }

            console.log("dataObject:", dataObject, "chatHistory:", chatHistory);
            /**************************************
             * IMPORTANT: Passage Activity Type uses this
             * to Trigger the next page after nextBtn
             * Clicked
             **************************************/
            if (currentActivityType === "passage" || !chatInteraction) {
              setPassageData(dataObject);
            }
          }
        }; // onstop
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    };
    //end startRecording

    /**
     * Step0 of 5: Stop microphone and checkSilence Looper
     */
    // const stopRecording = async () => {
    //   try {
    //     // Stop the MediaRecorder
    //     mediaRecorder.current?.stop();

    //     // Handle any asynchronous operations, such as processing the audio data
    //     const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
    //     audioChunks.current = []; // Clear the chunks for future recordings

    //     // Optionally process the audioBlob
    //     await processAudio(audioBlob);

    //     console.log("Recording stopped and audio processed.");
    //   } catch (error) {
    //     console.error("Error stopping recording:", error);
    //   }
    // };
    /**
     * Step0 of 5: Stop microphone and checkSilence Looper
     */
    const stopRecording = () => {
      // if(wordDetectedRef.current) {
      console.log("In Stop Recording");
      // Stop microphone
      if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
        mediaRecorder.current.stop();
      }
      // Stop checkSilence Looper
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      audioChunks.current = []; // Clear any remaining audio chunks
      // } // wordDetectedRef.current
    };

    // useEffect (()=> {
    //   console.log("BaseRecording: wordDetectedRef:", wordDetectedRef)
    //   setWordDetected(wordDetectedRef.current);
    // }, [wordDetectedRef]);

    useEffect(() => {
      setWordDetected(wordDetectedRef.current);
      console.log(
        "Should come after wordDetected is false",
        "wordDetectedRef:",
        wordDetectedRef,
        "isRecording:",
        isRecording
      );
      if (wordDetectedRef.current === false) {
        console.log("wordDetectedRef:", wordDetectedRef);
        // setIsRecording(false);
      }
    }, [wordDetected]);

    return (
      <WrappedComponent
        {...props}
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        passageData={passageData}
        activityCountdownTimerComplete={activityCountdownTimerComplete}
        setActivityCountdownTimerComplete={setActivityCountdownTimerComplete}
        isSilentRef={isSilentRef}
        isSilentForFiveSeconds={isSilentForFiveSeconds}
        silentAfterTalking4AtLeast5Seconds={silentAfterTalking4AtLeast5Seconds}
        isTalkingForFiveSeconds={isTalkingForFiveSeconds}
        startedTalking={startedTalking}
        toggle={toggle}
        type={type}
        startRecording={startRecording}
        stopRecording={stopRecording}
        setPassageData={setPassageData}
        chatHistory={chatHistory}
        setChatHistory={setChatHistory}
        audioHistory={audioHistory}
        setAudioHistory={setAudioHistory}
        processText={processText}
        processAudio={processAudio}
        playAudio={playAudio}
        wordDetectedRef={wordDetectedRef}
        wordDetected={wordDetected}
        rms={rms}
        reset={reset}
        stopSystemAudioPlayback={stopSystemAudioPlayback}
        initialQuestionAskedRef={initialQuestionAskedRef}
      />
    );
  };
};

export default withRecording;
