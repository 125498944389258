export const convertTranscriptionToText = (transcription, type) => {
  if (type === 'text') {
    console.log("convertTranscriptionToText: text file");
    const response = transcription
      .map((entry) => {
        if (entry.role === "user") {
          return `User said: ${entry.content}`;
        } else if (entry.role === "assistant") {
          return `System said: ${entry.content}`;
        }
        return ""; // Default case if role is not recognized
      })
      .filter((line) => line.trim() !== "") // Remove any empty lines
      .join("\n"); // Join all lines with a newline character

    console.log("ConvertTranscriptionToText:", response);

    // Create a File object instead of a Blob
    const textFile = new File([response], "transcription.txt", { type: "text/plain" });
console.log("Is File:", textFile instanceof File)
    return textFile; // Return the File object
  }
  else {
    console.log("convertTranscriptionToText: text blob");

    const response = transcription
      .map((entry) => {
        if (entry.role === "user") {
          return `User said: ${entry.content}`;
        } else if (entry.role === "assistant") {
          return `System said: ${entry.content}`;
        }
        return ""; // Default case if role is not recognized
      })
      .filter((line) => line.trim() !== "") // Remove any empty lines
      .join("\n"); // Join all lines with a newline character

    console.log("ConvertTranscriptionToText:", response);

    // Create a Blob from the text content
    const textBlob = new Blob([response], { type: "text/plain" });
    return textBlob;
  }
};
