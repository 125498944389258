import React from "react";
// import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import Text from "../../../global/inputs/Text";
// import SigninButton from "../../../global/inputs/SigninButton";
import { useDispatch, useSelector } from "react-redux";
// import { setUser } from "../../../redux/slices/userSlice";
// import { setNavigation } from "../../../redux/slices/navigationSlice";
import { useNavigate } from "react-router";
import CustomLink from "../../../global/outputs/CustomLink";

const CompletionPage = ({ flow }) => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.scenarios.global);
  console.log("USERDATA:", userData);
  const navigate = useNavigate();

  // const {
  //   register,
  //   control,
  //   handleSubmit,
  //   // formState: { errors },
  //   reset,
  // } = useForm({
  //   defaultValues: {
  //   },
  // });

  // const handleSubmitForm = async (data, event) => {
  //    // Prevent the default form submission behavior
  //    event.preventDefault();
  //    // Stop the event propagation to prevent it from bubbling up
  //    event.stopPropagation();
     
  //   // const newContent = "TitleSearchContent";
  //   let page = "";
  //   let path = "";
  //   if (flow === "lms") {
  //     page = "LinkAccount";
  //     path = "/lms/link";
  //   } else if (userData.bookSelected) {
  //     page = "CourseSetup";
  //     path = "/setup/product";
  //   }
  //   // book not selected
  //   else if(!userData.bookSelected) {
  //     page = "TitleSearchContent";
  //     path = "/title";
  //   }

  //   console.log("Signin Data:", data);
  //   const newUserData = { ...data, "signed-in": true };
   

  //   // await dispatch(setUser(newUserData));

  //   // const response = await dispatch(setNavigation({ page, path }));
  //   // console.log("Response:", response);
  //   // navigate(`${process.env.PUBLIC_URL}${response.payload.path}`);
  // };

  return (
    <form
      // onSubmit={handleSubmit(handleSubmitForm)}
      className="signin-form-content"
    >
      {/* <div className="signin-header">
        <h3 className="title">Sign in</h3>
        <div className="row create-account">
          New to Pearson?{" "}
          <CustomLink
            classLabel="create-account-link"
            newView="create-account"
            // to={`${process.env.PUBLIC_URL}/account/create`}
          >
            Create an account
          </CustomLink> 
        </div>
      </div>*/}
      {/* {userData?.lastName ? (
        <>
        //   <div className="login-group">
        //     <div className="form-group">
        //       <Text
        //         displayType="row"
        //         register={register}
        //         className="form-input"
        //         id="formGroupEmail"
        //         testId="login-email"
        //         label="Email address"
        //         labelClass="label-element"
        //         name="email"
        //         required={true}
        //       />
        //       <Text
        //         displayType="row"
        //         type="password"
        //         register={register}
        //         className="form-input"
        //         id="formGroupPassword"
        //         testId="login-password"
        //         label="Password"
        //         labelClass="label-element"
        //         name="password"
        //         required={true}
        //       />
        //       <p className="trouble-element">Trouble signing in?</p>
        //       <SigninButton
        //         label="Sign in"
        //         variant="attention"
        //         size="medium"
        //         className="adopt-btn"
        //         // newContent="TitleSearchContent"
        //         // newPage="Start"
        //         // newPath="/title"
        //       />
        //       <div className="row signin-terms">
        //         By signing in, you agree to our Terms of use and acknowledge our
        //         Privacy notice.
        //       </div>
        //     </div>
        //   </div>{" "}
        </>
      ) : (
        <></>
      )} */}
      <p>Test complete, thank you for your participation!</p>
    </form>
  );
};

export default CompletionPage;
