// Helper function to combine audio blobs
import {outputAudioBlob} from "./outputAudioBlob.js";

const bufferToWave = (abuffer) => {
  const numOfChannels = abuffer.numberOfChannels;
  const length = abuffer.length * numOfChannels * 2 + 44; // 2 bytes per sample + 44-byte header
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);

  // Write WAV header
  let offset = 0;

  // RIFF identifier
  writeString(view, offset, 'RIFF');
  offset += 4;

  // file length
  view.setUint32(offset, length - 8, true);
  offset += 4;

  // RIFF type
  writeString(view, offset, 'WAVE');
  offset += 4;

  // format chunk identifier
  writeString(view, offset, 'fmt ');
  offset += 4;

  // format chunk length
  view.setUint32(offset, 16, true);
  offset += 4;

  // sample format (raw)
  view.setUint16(offset, 1, true);
  offset += 2;

  // channel count
  view.setUint16(offset, numOfChannels, true);
  offset += 2;

  // sample rate
  view.setUint32(offset, abuffer.sampleRate, true);
  offset += 4;

  // byte rate (sample rate * block align)
  view.setUint32(offset, abuffer.sampleRate * numOfChannels * 2, true);
  offset += 4;

  // block align (channel count * bytes per sample)
  view.setUint16(offset, numOfChannels * 2, true);
  offset += 2;

  // bits per sample
  view.setUint16(offset, 16, true);
  offset += 2;

  // data chunk identifier
  writeString(view, offset, 'data');
  offset += 4;

  // data chunk length
  view.setUint32(offset, length - offset - 4, true);
  offset += 4;

  // Write PCM samples
  for (let i = 0; i < abuffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
          const sample = abuffer.getChannelData(channel)[i];
          view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true); // Convert to PCM format
          offset += 2;
      }
  }

  return new Blob([buffer], { type: 'audio/wav' });
};

// Helper function to write strings
function writeString(dataView, offset, string) {
  for (let i = 0; i < string.length; i++) {
      dataView.setUint8(offset + i, string.charCodeAt(i));
  }
}

export const createSilentBlob = async (durationSeconds = 2) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  
  // Create a silent buffer
  const silenceBuffer = audioContext.createBuffer(1, audioContext.sampleRate * durationSeconds, audioContext.sampleRate);
  const channelData = silenceBuffer.getChannelData(0);
  channelData.fill(0); // Fill with silence

  // Convert the silence buffer to a Blob
  return bufferToWave(silenceBuffer);
};


// 8/14/2024
export const combineAudioBlobs = async (audioBlobs) => {
  // Create an AudioContext, with a fallback for older browsers
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  // Filter out empty Blobs and ensure minimum length if needed
  const nonEmptyAudioBlobs = await Promise.all(
    audioBlobs.map(async (blob) => {
      if (blob.size === 0) {
        // Create a 2-second silence Blob for empty Blobs
        return createSilentBlob(); // Ensure 2 seconds of silence
      }
      return blob;
    })
  );

  // Decode each non-empty audio blob into an AudioBuffer
  const buffers = await Promise.all(nonEmptyAudioBlobs.map(async (blob) => {
    const arrayBuffer = await blob.arrayBuffer();
    return audioContext.decodeAudioData(arrayBuffer);
  }));

  // If there are no valid buffers, return an empty Blob
  if (buffers.length === 0) {
    return createSilentBlob(); // Return a silent Blob if needed
  }

  // Determine the number of channels to use (minimum number across all buffers)
  const numberOfChannels = Math.min(...buffers.map(buffer => buffer.numberOfChannels));

  // Calculate the total length of the combined audio
  const length = buffers.reduce((acc, buffer) => acc + buffer.length, 0);

  // Create a new AudioBuffer to hold the combined audio
  const combinedBuffer = audioContext.createBuffer(numberOfChannels, length, audioContext.sampleRate);

  // Offset to track the current position in the combined buffer
  let offset = 0;

  // Copy each buffer's data into the combined buffer
  buffers.forEach(buffer => {
    for (let channel = 0; channel < numberOfChannels; channel++) {
      combinedBuffer.getChannelData(channel).set(buffer.getChannelData(channel), offset);
    }
    offset += buffer.length;
  });

  // Convert the combined AudioBuffer back into a Blob
  const wavBlob = await outputAudioBlob(audioContext, combinedBuffer);
  return wavBlob;
};

