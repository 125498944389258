import React, { useEffect, useState, flushSync  } from "react";
import displaySvgIcon from "../../../utils/displaySvgIcon";
import Button from "./DownloadButton";
import { useForm } from "react-hook-form";
import Text from "../../inputs/Text";
import SigninButton from "../../inputs/SigninButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomLink from "../../outputs/CustomLink";

const SigninFormContent = ({ flow }) => {
  const dispatch = useDispatch();
  const candidates = useSelector((state) => state.scenarios.admin);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidatePassword, setCandidatePassword] = useState("");
  const [candidateSignedIn, setCandidateSignedIn] = useState(false);

  const [idInvalid, setIdInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordInvalidMsg, setpasswordInvalidMsg] = useState("Password");
  const [idInvalidMsg, setidInvalidMsg] = useState("UserName");

  const navigate = useNavigate();

  const {
    register,
    setValue,
    control,
    handleSubmit,
    reset,
  } = useForm();

  const handleSubmitForm = async (data, event) => {
    event.preventDefault();
    event.stopPropagation();
    const emailsEqual = data?.email.toLowerCase() === candidateEmail.toLowerCase();

    // if (emailsEqual) {
    //   setIdInvalid(false);
    //   if (data?.password === candidatePassword) {
    //     setCandidateSignedIn(true); // Set candidateSignedIn on successful login
    //     flushSync(() => {
    //         // Force the re-render synchronously if necessary
    //         console.log("Signed in true")
    //       });
    //   } else {
    //     setCandidateSignedIn(false); // Reset candidateSignedIn if password is incorrect
    //     flushSync(() => {
    //         // Force the re-render synchronously if necessary
    //         console.log("Signed in false")
    //       });
    //   }
    // } else {
    //   setIdInvalid(true);
    //   setidInvalidMsg("Username Invalid");
    // }

    // if (data?.password !== candidatePassword) {
    //   setPasswordInvalid(true);
    //   setpasswordInvalidMsg("Password Invalid");
    // } else {
    //   setPasswordInvalid(false);
    // }

    if (emailsEqual && data?.password === candidatePassword) {
        setIdInvalid(false);
        setPasswordInvalid(false);
        setCandidateSignedIn(true);  // Successfully signed in
      } else {
        if (!emailsEqual) {
          setIdInvalid(true);
        }
        if (data?.password !== candidatePassword) {
          setPasswordInvalid(true);
        }
        setCandidateSignedIn(false); // Handle invalid sign-in
      }
  };

  useEffect(() => {
    if (candidates.length > 0) {
      setCandidateEmail(candidates[0].email);
      setCandidatePassword(candidates[0].password);
    }
  }, [candidates]);

  useEffect(() => {
    if (candidateSignedIn) {
      console.log("Signed in successfully");
    } else {
      console.log("Sign in failed");
    }
  }, [candidateSignedIn]);

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} className="container">
      <div className="header item">
        <h3>Enter Credentials to Start Download:</h3>
      </div>
      <Text
        type="text"
        register={register}
        setValue={setValue}
        className="form-input item"
        id="formGroupEmail"
        testId="login-email"
        labelClass="label-element"
        name="email"
        required={true}
        placeholder={idInvalidMsg}
        placeholderClass={idInvalid ? "invalid placeholder-element" : "placeholder-element"}
        clearInput={idInvalid}
      />
      <Text
        type="password"
        register={register}
        setValue={setValue}
        className="form-input item"
        id="formGroupPassword"
        testId="login-password"
        labelClass="label-element"
        name="password"
        required={true}
        placeholder={passwordInvalidMsg}
        placeholderClass={passwordInvalid ? "invalid placeholder-element" : "placeholder-element"}
        clearInput={passwordInvalid}
      />

      <Button
        classLabel="item"
        text="Download Files"
        variant="primary"
        signedIn={candidateSignedIn} // Pass candidateSignedIn directly to Button
      />
    </form>
  );
};

export default SigninFormContent;
