import React, { useEffect, useState, useRef } from 'react';

/**
 * TextComponent dynamically adjusts font size to ensure text fits within the container.
 */
const TextComponent = ({ text }) => {
  const [truncatedText, setTruncatedText] = useState(text);
  const containerRef = useRef(null);
  const [fontSize, setFontSize] = useState(20); // Start at 20px

  useEffect(() => {
    setTruncatedText(text);
  }, [text]);

  useEffect(() => {
    const container = containerRef.current;

    const isOverflowing = (element) => {
      return element.scrollWidth > element.offsetWidth || element.scrollHeight > element.offsetHeight;
    };

    const adjustFontSize = () => {
      if (!container) return;

      const maxFontSize = 20;
      const minFontSize = 10;
      let currentFontSize = maxFontSize;

      while (currentFontSize >= minFontSize) {
        container.style.fontSize = `${currentFontSize}px`;

        if (!isOverflowing(container)) {
          break; // Stop reducing font size if text fits
        }

        currentFontSize -= 1; // Decrease font size
      }

      setFontSize(currentFontSize);
    };

    let resizeTimeout;

    const debouncedResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        adjustFontSize();
      }, 100); // Debounce delay of 100ms
    };

    const resizeObserver = new ResizeObserver(debouncedResize);

    if (container) {
      resizeObserver.observe(container);
      adjustFontSize(); // Initial adjustment
    }

    return () => {
      clearTimeout(resizeTimeout);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <span
      className="instructions"
      ref={containerRef}
      style={{
        fontSize: `${fontSize}px`,
        overflow: 'hidden',
        wordWrap: 'break-word',
      }}
    >
      {truncatedText.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index !== truncatedText.split('\n').length - 1 && <br />}
        </React.Fragment>
      ))}
    </span>
  );
};

export default TextComponent;
