import React from 'react';
// import DownloadButton from '../inputs/DELETE-DownloadButton';
import SigninForm from '../widgets/authenticationtion/SigninForm';


const DownloadTemplate = () => {
    return (
        <div className='signin-page'>
            <div className='login-container'>

                {/* <DownloadButton /> */}
                <SigninForm />
            </div>
        </div>
    );
};

export default DownloadTemplate;