import { transcribeAudio } from "../OpenAi"
import langid from 'langid';

// Function to clean the transcribed text
const cleanText = (text) => {
  return text
    .replace(/[^\w\s]|_/g, '')  // Remove punctuation
    .replace(/\s+/g, ' ')       // Replace multiple spaces with a single space
    .trim();                    // Trim whitespace from start and end
};

export const processAudioChunk = async (audioBlob) => {
  console.log("In ProcessAudioChunk");
    const transcribedText = await transcribeAudio(audioBlob); // Replace with your transcription function
console.log("Transcribed Text:", transcribedText);
    if (!transcribedText) {
      console.error("Transcription failed.");
      return false;
    }
  
    // Clean and tokenize the transcribed text
    const cleanedText = cleanText(transcribedText);
    const tokens = cleanedText.split(/\s+/);
  
    // Check for English words in the tokens
    const englishTokens = tokens.filter((token) => {
      const detections = langid.detect(token); // Language detection for each token
      const englishDetection = detections.find(([lang]) => lang === "english");
      return englishDetection && englishDetection[1] >= 0.23; // Adjust threshold as needed
    });
  
    return englishTokens.length > 0; // Return true if any English word is detected
  };
  