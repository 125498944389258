import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import VideoPlayer from "../../../global/widgets/VideoPlayer";
import Button from "../../../global/inputs/Button";
import { playAudio } from "../../../utils/OpenAi";
import displaySvgIcon from "../../../utils/displaySvgIcon";
import {
  updateNextButtonStatus,
  updateGlobalValue,
} from "../../../redux/slices/scenarioSlice";


const Instructions = () => {
  const ComponentMap = {
    VideoPlayer: VideoPlayer,
  };
  const dispatch = useDispatch();
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [instructionsPlaying, setInstructionsPlaying] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const scenarioData = useSelector((state) => state.scenarios);
  const currentActivityType = scenarioData.global["current-activity-type"];
  const root = scenarioData[currentActivityType];
  const currentLayout = scenarioData.global["current-layout"];
  console.log(
    "Current View:",
    currentLayout,
    "CURRENT PAGE:",
    currentActivityType
  );
  // console.log("Scenario Data:", scenarioData);
  // console.log("ROOT:", root);
  const viewMainData = root.views[currentLayout].main;
  // console.log("ViewMainData:", viewMainData);
  const columnsData = viewMainData.columns;
  const columnsNum = columnsData.length;
  // console.log("ColumnsData:", columnsData, columnsNum);
  const leftData = columnsData[0].left;
  const title = leftData.title;
  const body = leftData.body;
  const durationMessage = leftData["duration-message"];
  const rightData = columnsData[1].right;
  const videoData = rightData.data;
  // console.log("VIDEO DATA:", videoData);
  // console.log("RightData:", rightData);
  const componentName = rightData.component;
  const VideoComponent = ComponentMap[componentName];
  // const videoData = rightData.data

  // let isButtonDisabled = false;

  const handlePlayAudio = async (text) => {
    const result = await playAudio(text);
    // console.log("RESULT:", result);
    if (result) {
      setAudioPlayed(result);
      // console.log("Launching Play Audio");
      // console.log("Audio finished playing successfully.");
    } else {
      console.log("An error occurred while playing the audio.");
    }
  };

  const handlePageInstruction = () => {
    // disable next button until instructions played
    dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));

    // set InstructionsPlaying to true
    setInstructionsPlaying(true);
    const instructions = title + " " + body;
    console.log("Instruction:", instructions);
    const modifiedInstructions = instructions.replace(/\bread\b/gi, "red");

    // Play Instructions
    handlePlayAudio(modifiedInstructions);
  };

  useEffect(() => {
    //Reset flags when audioPlayed is complete
    if (audioPlayed) {
      dispatch(
        updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
      );
      setInstructionsPlaying(false);
      setAudioPlayed(false);
    }
    if (
      !audioPlayed &&
      !instructionsPlaying &&
      !isButtonDisabled &&
      currentActivityType === "passage"
    ) {
      dispatch(
        updateGlobalValue({
          key: "current-stepper-segment",
          value: "instructions",
        })
      );
    }
  }, [audioPlayed, instructionsPlaying]);

  useEffect(() => {
    if (currentActivityType === "advice") {
      dispatch(
        updateGlobalValue({
          key: "current-stepper-segment",
          value: "advice-instructions",
        })
      );
    }
    dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: false }));
  }, []);

  return (
    <>
      <div className="instructions-container">
        <div className="scenario instructions hide">
          {/* <SystemActive action="Thinking" /> */}
          <div className="title scenario">{title}</div>
          <div className="body">{body}</div>
          {/* <Button
            buttonClass="play-btn"
            label="Listen to instructions"
            image={displaySvgIcon("play")}
            variant="primary"
            size="lg"
            {...(instructionsPlaying && { disabled: instructionsPlaying })}
            onClick={handlePageInstruction}
          /> */}
          {durationMessage? <div className="duration-message">{durationMessage}</div> : null}
        </div>
        <div className="scenario video">
          <VideoComponent />
        </div>
      </div>
    </>
  );
};

export default Instructions;
