// CountDownBar.js
import React, { useEffect, useState } from "react";
import Button from "../../global/inputs/Button";
// import './CountDownBar.css'; // Import CSS for styling
import { minutesToSeconds } from "../../utils/minutesToSeconds";
import { useSelector, useDispatch } from "react-redux";

/**
 * CountDownBar Component
 *
 * A visual progress bar that starts completely filled and unfills from left to right
 * based on the remaining time in a countdown.
 * 
 * IMPORTANT: CountDownBar is is Totally Independent of  CountDownTimer
 *
 * @param {Object} props - Component properties
 * @param {number} props.countdownDuration - The total duration of the countdown in seconds
 * @param {number} props.timeLeft - The remaining time in seconds
 *
 * @returns {JSX.Element} The rendered CountDownBar component
 */
const CountDownBar = ({ 
  timeLeft , 
  countdownDuration, 
  countdownUnit,
  overrideActivityCounter,
}) => {
  /********************************************
   * REDUX STATE
   ********************************************/
  const scenarioData = useSelector((state) => state.scenarios);
  const globalData = useSelector((state) => state.scenarios.global);
  const userId = globalData.id;
  const currentActivityType = scenarioData.global["current-activity-type"];
  /********************************************/
  // Calculate the percentage of the bar that should be unfilled
  //   const percentageUnfilledRightToLeft = ((countdownDuration - timeLeft) / countdownDuration) * 100;
  let percentageUnfilledLeftToRight;
  if (countdownUnit === "minutes" || countdownUnit === "minute") {
    const durationInSeconds = minutesToSeconds(countdownDuration)
    percentageUnfilledLeftToRight= (timeLeft / durationInSeconds) * 100;
  } else {
    percentageUnfilledLeftToRight= (timeLeft / countdownDuration) * 100;
  }
  const percentIsNumber = typeof percentageUnfilledLeftToRight === "number";

  // console.log(
  //   "*CountDownBar: percentageUnfilled0:",
  //   percentageUnfilledLeftToRight,
  //   "countdownDuration:",
  //   countdownDuration,
  //   "timeLeft:",
  //   timeLeft
  // );
  const handlePageOverride = () => {
    // console.log("*handlePageOverride button clicked");
    overrideActivityCounter(true);
  };

  const getTimerMessage = () => {
    let time;
    let unit;
// console.log("GetTimerMessage: countdownDuration:", countdownDuration, "countdownUnit:", countdownUnit, "currentActivityType:", currentActivityType)
    if ( countdownUnit === "seconds" && (currentActivityType === "advice")) {
      time = countdownDuration;
      unit = countdownUnit;
    } else if ((countdownUnit === "seconds" && currentActivityType === "chat") || (countdownUnit === "seconds" && currentActivityType === "chat-written") || (countdownUnit === "seconds" && currentActivityType === "opinion")) {
      time = countdownDuration / 60;
      unit = "minute";
    }
    return ` ${time} ${unit} `;
  }

  useEffect(() => {
    if(countdownDuration && timeLeft && percentIsNumber) {
    // console.log(
    //   "*CountDownBar: percentageUnfilled1:",
    //   percentageUnfilledLeftToRight,
    //   "countdownDuration:",
    //   countdownDuration,
    //   "timeLeft:",
    //   timeLeft
    // );
    // setCountdown(percentageUnfilled);
  }
  }, [timeLeft]);

  return (
    <>
    {percentIsNumber ? (<div className="countdown-bar-container">
      <div className="countdown-bar">
        <div className="subbar"></div>
        <div
          className="countdown-bar-fill"
          // Set the width of the filled portion based on the percentage
          style={{
            width: percentageUnfilledLeftToRight
              ? `${percentageUnfilledLeftToRight}%`
              : "100%",
          }}
        />
      </div>
      <div className="duration-message">
        <span>You have</span>
        {/* <span>{` ${countdownDuration} ${countdownUnit} `}</span> */}
        <span>{getTimerMessage()}</span>
        <span>to prepare...</span> {}
      </div>
      <Button
        buttonClass="override-btn"
        label="Start now"
        // image={displaySvgIcon("play")}
        variant="primary"
        size="lg"
        // {...(instructionsPlaying && { disabled: instructionsPlaying })}
        onClick={handlePageOverride}
      /> 
    </div>) : <></>}
    </>
  );
};

export default CountDownBar;
