// import React from "react";
import "./assets/css/style.css";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import { store, persistor } from "./redux/store";
import { library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import {
  faArrowsLeftRight,
  faBars,
  faBookmark,
  faChevronDown,
  faChevronUp,
  faPencil,
  faPlus,
  faServer,
  faTrash,
  faThumbTack,
  faUser,
  faVolumeHigh,
  faVolumeXmark,
  faXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { faClock } from "@fortawesome/free-regular-svg-icons";

import Translation from "./page/Translation";
import Advice from "./page/Advice";
import Chat from "./page/Chat";
import Opinion from "./page/Opinion";
// import AudioRecorder from "./components/AudioRecorder";
import AudioRecorder from "./components/AudioRecorder";
import GenericTemplate from "./global/wrappers/GenericTemplate";
import DownloadTemplate from "./global/wrappers/DownloadTemplate";
import Instructions from "./layout/content/global/Instructions";
import AdviceInstructionReview from "./layout/content/advice/AdviceInstructionReview";
library.add(
  faArrowsLeftRight,
  faSpinner,
  faBars,
  faBookmark,
  faChevronDown,
  faChevronUp,
  faClock,
  faPencil,
  faPlus,
  faServer,
  faTrash,
  faThumbTack,
  faUser,
  faVolumeHigh,
  faVolumeXmark,
  faXmark
);

function App() {
  // const basename = `${process.env.PUBLIC_URL}`;

  return (
    <div className="App">
      {/* <Router basename={basename}> */}
      <Router>
        <Routes>
          {/* NEW WORK: Remember to update current-view variable*/}
          {/* <Route path="/" element={<Navigate to="/signin" />} />
            <Route path="/signin" element={<GenericTemplate/>} />
            <Route path="/create" element={<GenericTemplate/>} /> */}
          {/************************************************/}
          {/* Test ONLY */}
          {/* <Route path="/test" element={<AudioRecorder />} /> */}

          {/* Real Routes */}
          <Route path="/" element={<GenericTemplate />} />
          <Route path="/download" element={<DownloadTemplate />} />
          {/* <Route path="/" element={<Navigate to="/translation" />} /> 
              <Route path="/translation" element={<GenericTemplate />} /> */}

          {/************************************************/}
          {/* <Route path="/advice" element={
            <GenericTemplate>
              <Advice>
                <AdviceInstructionReview/>
                </Advice>
            </GenericTemplate>}/> */}
          {/* <Route path="/advice" element={<GenericTemplate />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/opinion" element={<Opinion />} /> */}

          {/* <Route path="/english" element={<PassageEnglish/>} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
