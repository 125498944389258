import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  updateTranslationValue,
  updateAdviceValue,
  updateKeyByActivity,
} from "../../redux/slices/scenarioSlice";
import { minutesToSeconds } from "../../utils/minutesToSeconds";
/**
 * MicrophoneSpeakTimer2
 *
 * This component is responsible for displaying a countdown timer that tracks the
 * amount of time left for the user to speak. It updates the activityType/circular-progress-percenatage
 * in the Redux store with the time left converted to the progress percentage over time.
 *
 * It is to be used in combination with the CircularProgressIndicator2 component.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.setSpeakTimeComplete - Callback function to notify when speaking time is complete.
 * @param {number} props.endTime - The end time for the timer.
 * @param {boolean} props.isDataUploaded - Flag indicating if the data is uploaded.
 * @param {number} props.reocordingDuration - Initial time for the timer in seconds.
 * @param {boolean} props.isTimerComplete - Flag indicating if the timer is complete.
 * @param {boolean} props.disableCircularProgressbar - Flag to disable circular progress bar updates.
 * @param {string} props.currentActivityType - Identifier for the currentActivityType of state to update ('passage' or 'advice').
 */
const MicrophoneSpeakTimer2 = ({
  setSpeakTimeComplete,
  endTime,
  isDataUploaded,
  // clockTime,
  isTimerComplete,
  disableCircularProgressbar,
  recordingDuration,
  recordingUnit,
  classLabel,
}) => {
  const scenarioData = useSelector((state) => state.scenarios);
  const currentActivityType = scenarioData.global["current-activity-type"];

  // console.log("MicrophoneSpeakTimer2: Recording Duration1:", recordingDuration, 
//     "RecordingUnit:", recordingUnit, 
//     "isTimerComplete:", isTimerComplete,
//     "isDataUploaded:", isDataUploaded,
//   "disableCircularProgressbar:", disableCircularProgressbar,
// "CurrentActivityType:", currentActivityType);
  const [clockTime, setClockTime] = useState(
    recordingDuration && recordingUnit === "seconds"
      ? recordingDuration
      : ((recordingDuration && recordingUnit === "minutes") || (recordingDuration && recordingUnit === "minute"))
      ? minutesToSeconds(recordingDuration)
      : 0
  );

  // console.log("MicrophoneSpeakTimer2: clockTime:", clockTime);
  const dispatch = useDispatch();
  const initialTime = clockTime ? clockTime : 60; // Start timer at clockTime if provided, else 60 seconds
  const [time, setTime] = useState(initialTime);
  const [isComplete, setIsComplete] = useState(false);


  useEffect(() => {
    if (!isTimerComplete) {
      return; // Stop the effect if data is uploaded or timer is not complete
    }

    // console.log("Starting timer with initialTime:", time);
    const timerId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime < endTime) {
          clearInterval(timerId);
          setIsComplete(true); // Set the completion flag
          return endTime;
        }
        return prevTime - 1;
      });
    }, 1000);


    return () => clearInterval(timerId); // Cleanup the interval on component unmount
  }, [endTime, setSpeakTimeComplete, isDataUploaded, isTimerComplete]);

  useEffect(() => {
    if (isComplete && setSpeakTimeComplete) {
      setSpeakTimeComplete(true); // Trigger the completion callback only in a side effect
    }
  }, [isComplete, setSpeakTimeComplete]);

  useEffect(() => {
    if (
      !disableCircularProgressbar &&
      clockTime &&
      time &&
      currentActivityType === "passage"
    ) {
      dispatch(
        updateTranslationValue({
          key: "circular-progress-percentage",
          value: calculatePercentage(),
        })
      );
    } else if (
      !disableCircularProgressbar &&
      clockTime &&
      time &&
     (currentActivityType === "advice" || currentActivityType==="chat")
    ) {
      // dispatch(
      //   updateAdviceValue({
      //     key: "circular-progress-percentage",
      //     value: calculatePercentage(),
      //   })
      // );
      dispatch(updateKeyByActivity({
        activityType: currentActivityType,
        key: "circular-progress-percentage",
        value: calculatePercentage(),
      }))
    }
    // console.log("Time:", time);
  }, [time]);

  /* Formats seconds in decimals to minutes */
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const calculatePercentage = () => {
    const percentage = Math.ceil(((initialTime - time) / initialTime) * 100);
    // console.log("initialTime:", initialTime, "time:", time, "PERCENTAGE:", percentage);
    return percentage; // Round to the nearest integer
  };

  return currentActivityType === "passage" || "chat"? (
    <div className={isDataUploaded ? "hide" : `${classLabel} speak-timer-container`}>
      <div className="speaker-container">
        <span>Speak...</span>
      </div>
      {/* <div className="speak-timer">
        <FontAwesomeIcon
          icon="fa-regular fa-clock"
          style={{ color: "#A7A7A7" }}
        />
        <span>{formatTime(time)}</span>
      </div> */}
    </div>
  ) : (
    <></>
  );
};

MicrophoneSpeakTimer2.propTypes = {
  setSpeakTimeComplete: PropTypes.func,
  endTime: PropTypes.number.isRequired,
  isDataUploaded: PropTypes.bool.isRequired,
  recordingDuration: PropTypes.number,
  recordingUnit: PropTypes.string,
  isTimerComplete: PropTypes.bool.isRequired,
  disableCircularProgressbar: PropTypes.bool,
  // currentActivityType: PropTypes.string.isRequired,
};

export default MicrophoneSpeakTimer2;
