// Main component file
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import CountDownTimer from "../../../global/outputs/CountDownTimer";
import MicrophoneSpeakTimer from "../../../global/outputs/MicrophoneSpeakTimer";
import CircularProgressIndicator from "../../../global/widgets/CircularProgressIndicator";
import RowContainer from "../../../components/RowContainer";
import { uploadToFirebase } from "../../../utils/firebase";
import { findAssistantResponse } from "../../../utils/findAssistantResponse";
import {
  updateTranslationValue,
  updateGlobalValue,
  updateNextButtonStatus,
  removeActivityScenariosItem,
  updateAdviceValue,
  updateKeyByActivity,
} from "../../../redux/slices/scenarioSlice";
import WithRecording from "../global/hoc/OpinionRecording";
import ChatHistoryText from "../../../global/widgets/chatbot/ChatHistoryText";
import ChatHistoryAudio from "../../../global/widgets/chatbot/ChatHistoryAudio";
import TextComponent from "../../../components/TextComponent";
import CountDownBar from "../../../global/outputs/CountDownBar";
import { convertTranscriptionToText } from "../../../utils/convertTranscriptionToText";
// import { current } from "@reduxjs/toolkit";

/**********************
 * Display data based on:
 * (1) current-activity-type
 * (2) current-layout
 **********************/
const OpinionInstructionReview = ({
  nextBtnRef,
  nextBtnClicked,
  isRecording,
  setIsRecording,
  passageData,
  setPassageData,
  activityCountdownTimerComplete,
  setActivityCountdownTimerComplete,
  isSilentRef,
  isSilentForFiveSeconds,
  silentAfterTalking4AtLeast5Seconds,
  isTalkingForFiveSeconds,
  startedTalking,
  toggle,
  type,
  startRecording,
  stopRecording,
  chatHistory,
  setChatHistory,
  audioHistory,
  setAudioHistory,
  processText,
  processAudio,
  playAudio,
  wordDetectedRef,
  wordDetected,
  initialQuestionAskedRef,
  reset,
}) => {
  /*****************************************
   * REDUX STATE VARIABLES:
   *****************************************/
  const scenarioData = useSelector((state) => state.scenarios);
  const globalData = useSelector((state) => state.scenarios.global);
  const userId = globalData.id;
  const currentActivityType = scenarioData.global["current-activity-type"];
  // console.log("currentActivityType:", currentActivityType);
  const currentLayout = scenarioData.global["current-layout"];
  // console.log("currentLayout:", currentLayout);
  const currentDataByActivityType = scenarioData[currentActivityType];
  // console.log("currentDataByActivityType:", currentDataByActivityType);
  const allScenariosByActivityType = currentDataByActivityType.scenarios_copy;
  // console.log("AllScenariosByActivityType:", allScenariosByActivityType);
  const currentScenario = currentDataByActivityType.shiftedItem;
  // console.log("CurrentScenario:", currentScenario, "isRecording:", isRecording);
  const currentScenarioType = currentScenario && currentScenario["type"];

  const countdownDuration = 60;
  const countdownUnit = "seconds";

  const allScenariosUploaded =
    currentDataByActivityType["all-scenarios-uploaded"];
  const images = currentScenario?.images;
  // console.log("Images:", images);
  const currentStepperSegment = globalData["current-stepper-segment"];
  const originalLabel = currentScenario["original-label"];
  const newLabel = currentScenario["new-label"];
  const description = `${currentScenario?.details}\n\n ${currentScenario?.instructions}`;
  const questions = currentScenario?.questions;
  // console.log("Questions:", questions);
  /*********************************************
   * REACT STATE VARIABLES
   ********************************************/
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const [userStoppedTalking, setUserStoppedTalking] = useState(false);
  const [chatTurnCounter, setChatTurnCounter] = useState(1);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const audioPlayedRef = useRef(audioPlayed);
  const [systemSpeaking, setSystemSpeaking] = useState(false);
  const [currentCountdownTime, setCurrentCountdownTime] = useState(60);
  const [initialSystemQuestion, setInitialSystemQuestion] = useState("");
  const [submitBtnClicked, setSubmitBtnClicked] = useState(
    nextBtnClicked.current
  );

  /*************************************************
   * REFERENCES
   *************************************************/
  const prevTypeRef = useRef();
  const systemPromptRef = useRef(
    "Your name is Jamie.  You will deliver a 10 minute presentaion tomorow at work, during a team meeting.  You have identified four different presentation styles. You would like to discuss the styles with your colleague and obtain their opinion on each style.  Have a polite conversation with one of your colleague about the styles.  Your colleague will be speaking to you in English. If you hear anything that sounds like a different language please disregard it, or ask them to repeat their comment, as you didn't hear it clearly.  Keep your questions and responses concise in no more than two sentences."
  );
  // const systemPromptRef = useRef(currentScenario?.prompt);
  const initialSystemQuestionRef = useRef(
    "What’s your overall impression of the coffee shop as a meeting spot?"
  );
  // const initialSystemQuestionRef = useRef(currentScenario?.questions[0]);
  // const initialSystemQuestionRef = useRef(currentScenario?.question);
  const systemSpeakingRef = useRef(systemSpeaking);
  const currentSourceRef = useRef(null);
  const currentAudioContextRef = useRef(null);
  const userStoppedTalkingRef = useRef(userStoppedTalking);
  /***************************************************
   * CONSTANTS
   ***************************************************/
  const prevType = prevTypeRef.current;
  const systemErrorResponse =
    "I'm sorry, I didn't quite catch that. Could you please speak a little louder?";
  const isSystemAudioResponseRequested = false;
  const typeNumber = 4;
  const totalNumberOfScenarios = 4;

  const dispatch = useDispatch();
  const activityTypeEndingSegment = "opinion-pet-options";
  /*****************************
   * FUNCTIONS
   *******************************/
  const scenarioLoaded = (() => {
    return (
      currentScenario &&
      typeof currentScenario === "object" &&
      Object.keys(currentScenario).length > 0
    );
  })();

  /****************************************
   * Renders all the images for the scenario
   *****************************************/
  // const displayImages = images?.map((image) => (
  //   <img src={image} alt="Scenario" />
  // ));
  const displayImages = () => {
    if (
      !images ||
      images.length < 2 ||
      images[0].length === 0 ||
      images[1].length === 0
    ) {
      return null; // Return null or a loading placeholder if data isn't available
    }
    const styles = {
      maxWidth: currentScenario.meta === "opinion-tap-water" ? "200px" : undefined,
      marginRight: currentScenario.meta === "opinion-tap-water" ? "15px" : undefined,
    };
    const margins = { 
      marginLeft: currentScenario.meta === "opinion-tap-water" ? "30px" : undefined
    }
    return (
      <>
        <div className="image-row">
          {images[0]?.map((image, index) => (
            <div className="image-item" key={index}>
              <div className="image-wrapper">
                {/* Circle with number */}
                <div className={currentScenario.meta === "opinion-tap-water" ? "image-circle-water" : "image-label-circle"}>{index + 1}</div>{" "}
                {/* Image */}
                <img src={image} alt={`Scenario ${index + 1}`} style={styles} />
              </div>
              <div className="image-wrapper" style={margins}>
                <p>{images[1][index]}</p>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  /********************************************
   * INCREMENT TURNCOUNTER
   *********************************************/
  const incrementTurnCounter = () => {
    setChatTurnCounter((prevCounter) => prevCounter + 1);
  };

  const silenceChatbotAndEnableUser = () => {
    audioPlayedRef.current = true;
    setAudioPlayed(true);
    setSystemSpeaking(false);
    systemSpeakingRef.current = false;

    // reset Recording state
    wordDetectedRef.current = null;
    if (!isRecording) {
      setIsRecording(true);
    }
  };

  /**************************
   * Silence user and sets
   * systemSpeaking
   ***************************/
  const silenceUserAndEnableChatbot = () => {
    audioPlayedRef.current = false;
    setSystemSpeaking(true);
    systemSpeakingRef.current = true;
  };

  // Setup state for inital load
  const setupCurrentScenarioInitialState = () => {
    // console.log(
    //   "Current Scenario:",
    //   "Prompt:",
    //   systemPromptRef.current,
    //   "Question:",
    //   initialSystemQuestionRef.current,
    //   "nextBtnClicked:",
    //   nextBtnClicked.current
    // );
    /**************************************/
    // reset passagedata
    setPassageData(null);
    setIsDataUploaded(false);
    setActivityCountdownTimerComplete(false);

    // System Audio Reset
    setAudioPlayed(false);
    audioPlayedRef.current = false;
    systemSpeakingRef.current = false;
    setSystemSpeaking(false);
    setUserStoppedTalking(false);
    setCurrentCountdownTime(countdownDuration);
    setIsRecording(false);
    initialQuestionAskedRef.current = false;

    /*********************************************/
    // Kill last system response
    if (currentScenario) {
      setActivityCountdownTimerComplete(false);
      dispatch(
        updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
      );
      setChatHistory([]);
      setAudioHistory([]);
      // Set chatTurnCounter
      setChatTurnCounter(1);
      systemPromptRef.current = currentScenario.prompt || "";
      initialSystemQuestionRef.current = currentScenario.questions[0] || "";
    }
  };
  /***************************
   * setupNextScenario: Resets state
   * variables for the next scenario
   * within the activityType
   *****************************/
  const setupNextScenario = () => {
    // console.log("SetupNextScenario:", "chatHistory:", chatHistory);

    setPassageData(null);
    // setIsDataUploaded(false);
    setActivityCountdownTimerComplete(false);
    setIsRecording(false);
    setChatHistory([]);
    setAudioHistory([]);

    // System Audio Reset
    setAudioPlayed(false);
    audioPlayedRef.current = false;
    systemSpeakingRef.current = false;
    setSystemSpeaking(false);
  };

  /******************************************
   * handlePlayAudio: Sends text to openAI to:
   * (1) Play through the users speakers
   * (Audio Only) only if the currentScenario
   * (shiftedItem)is not complete
   * (2) Reset audio state when done, and sets
   * is recording true.
   * (3) Enable submit button after chatbot
   * response
   * (4) Set initialQuestionAsked to true
   ******************************************/
  const handlePlayAudio = async (text) => {
    // console.log(
    //   "handlePlayAudio: text:",
    //   text,
    //   "initialSystemQuestionRef.current:",
    //   initialSystemQuestionRef.current,
    //   "InitialQuestionAsked:",
    //   initialQuestionAskedRef.current
    // );
    if (!initialQuestionAskedRef.current) {
      // console.log(
      //   "handlePlayAudio: text:",
      //   text,
      //   "initialSystemQuestionRef.current:",
      //   initialSystemQuestionRef.current,
      //   "InitialQuestionAsked:",
      //   initialQuestionAskedRef.current
      // );
    }

    if (!currentScenario?.complete) {
      let result;

      /*******************************
       * 1) Play Audio for subsequent
       * renders only if they do not
       * contain the same systemQuestion
       * is not the 1rst question in
       * the systems chatHistory content
       *******************************/
      if (text && typeof text === "string" && text.trim().length > 0) {
        // console.log(
        //   "REALTEXT: TEXT:",
        //   text,
        //   "InitialQuestionAsked:",
        //   initialQuestionAskedRef.current,
        //   "nextBtnClicked.current:",
        //   nextBtnClicked.current,
        //   "chatHistory:",
        //   chatHistory,
        //   "initialSystemQuestionRef.current:",
        //   initialSystemQuestionRef.current
        // );
        result = await playAudio(
          text,
          currentSourceRef,
          currentAudioContextRef
        );
      } else {
        console.log(
          "ERROR TEXT: InitialQuestionAsked:",
          initialQuestionAskedRef.current
        );
        // stopSystemAudioPlayback();
        result = await playAudio(
          systemErrorResponse,
          currentSourceRef,
          currentAudioContextRef
        );
      }
      // console.log("AudioPlayed:", result);

      if (result) {
        // console.log("Launching Play Audio: Result", result);
        // console.log("Audio finished playing successfully.");

        /*****************************************
         * (2) Reset audio state after audio played
         * Set isRecording to true
         *****************************************/
        silenceChatbotAndEnableUser();

        /**************************************
         * (3) If the initial question has been
         * asked enable the submit button
         * after the chatbot response is received
         **************************************/
        if (initialQuestionAskedRef.current && chatTurnCounter > 4) {
          dispatch(
            updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
          );
        }

        // (4) Set Initial question asked true
        initialQuestionAskedRef.current = true;
      } else {
        console.log(
          "HandlePlayAudio: An error occurred while playing the audio."
        );
      }

      // console.log(
      //   "scenarioLoaded0: ",
      //   scenarioLoaded,
      //   "type:",
      //   type,
      //   "activityCountdownTimerComplete:",
      //   activityCountdownTimerComplete,
      //   "isRecording :",
      //   isRecording,
      //   "userStoppedTalking:",
      //   userSilent(),
      //   "audioPlayed:",
      //   audioPlayed,
      //   "systemSpeaking",
      //   systemSpeaking,
      //   "userSilent():",
      //   userSilent(),
      //   "allScenariosUploaded:",
      //   allScenariosUploaded,
      //   "scenarioLoaded:",
      //   scenarioLoaded,
      //   "currentScenario.complete:",
      //   currentScenario.complete,
      //   "allScenariosUploaded:",
      //   allScenariosUploaded,
      //   "systemSpeaking:",
      //   systemSpeaking
      // );
    }
  };

  /*********************
   * userSilent: Check if user
   * is silent
   **********************/
  const userSilent = () => {
    return type === "silent" && userStoppedTalking;
  };

  /****************************
   * Step 0: handleShiftScenario:
   * Gets the scenario data from chat
   ****************************/
  const handleShiftScenario = () => {
    dispatch(removeActivityScenariosItem(currentActivityType));
  };

  /****************************************
   * USEEFFECTS
   ****************************************/
  /**************************************
   * Step 0: Compare current type to previous
   * type.  To determine when the user stops
   * speaking in:
   * ChatHistoryAudio -> ChatActiveMicrophone
   * ChatActiveMicrophone sets userStoppedTalking
   ***************************************/
  // Returns the previous type reference
  useEffect(() => {
    prevTypeRef.current = type;
    // console.log("PrevTypeRef:", prevTypeRef.current, "CurrentType:", type);
  }, [type]);

  /****************************
   * Step 1: Pull the first scenario
   *  if data is not upLoaded.
   * (1) Determine if state requires an initial reset:
   * (2) Pull the next scenario
   * (3) setup the inital state for the currentScenario
   ******************************/
  useEffect(() => {
    /**********************************
     * (1) Determine if state requires an initial reset:
     * Each time the button is clicked
     * Pull in the next scenario and reset
     * state, while more scenarios exist:
     **********************************/
    const moreScenariosExist =
      allScenariosByActivityType.length < totalNumberOfScenarios &&
      allScenariosByActivityType.length >= 1;

    const initialRender =
      !isDataUploaded &&
      !allScenariosUploaded &&
      allScenariosByActivityType.length === totalNumberOfScenarios;

    const subsequentLoads =
      currentScenario.complete && isDataUploaded && moreScenariosExist;

    // console.log(
    //   "InitialRender:",
    //   initialRender,
    //   "SubsequentLoads:",
    //   subsequentLoads,
    //   "isDataUploaded:",
    //   isDataUploaded,
    //   "allScenariosUploaded:",
    //   allScenariosUploaded,
    //   "allScenariosByActivityType.length:",
    //   allScenariosByActivityType.length,
    //   "currentScenario",
    //   currentScenario,
    //   "currentScenario.complete:",
    //   currentScenario.complete
    // );
    if (initialRender) {
      // 8/21/2024: Reset nextBtnClicked Reference for new scenario
      // nextBtnClicked.current = false;
    }
    //  (1) Determine what scenario we are pulling
    if (initialRender || subsequentLoads) {
      // if (initialRender || subsequentLoads || lastScenario) {
      // (2) Pull the next scenario
      handleShiftScenario();
      // currentScenario = currentDataByActivityType.shiftedItem;
      // console.log(
      //   "currentScenario meta:0",
      //   currentScenario,
      //   "currentActivityType:",
      //   currentActivityType,
      //   "dataByActivityType:",
      //   currentDataByActivityType
      // );
    }
    /****************************************
     * Handle Transition to next Layout:
     * If there are no more scenarios the
     * previous item's scenarioItem was complete
     * but since the last item was loaded the
     * currentScenario will be complete on btn click.
     * The following state variables should be set:
     * (1 Done) (Footer btn click) currentScenario.complete = true
     * (2 Done) (CIR on currentScenario.complete) isDataLoaded = true
     * (3) (CIR) chat.allScenariosUploaded = true
     * (4) (CIR) chat.complete = true
     ****************************************/
    // then set allScenariosUploaded and chat.complete to true
    else if (!moreScenariosExist && isDataUploaded) {
      // console.log(
      //   "We are now on the last scenario:",
      //   "currentScenario:",
      //   currentScenario,
      //   "allScenariosByActivityType:",
      //   allScenariosByActivityType,
      //   "isDataUploaded:",
      //   isDataUploaded,
      //   "currentScenario.complete:",
      //   currentScenario.complete
      // );
    }
    // (2) If data loaded reinitialize the state
    if (currentScenario.meta && !currentScenario.complete) {
      // console.log(
      //   "currentScenario meta:1",
      //   currentScenario,
      //   "currentActivityType:",
      //   currentActivityType,
      //   "dataByActivityType:",
      //   currentDataByActivityType
      // );
      setupCurrentScenarioInitialState();
    }
  }, [isDataUploaded, currentScenario, currentScenario.meta]);

  /**************************
   * Step 2: Call OpenAi to
   * play initial question:
   * (1) Set system speaking
   * (2) if initalQuestion set,
   * Play the audio.
   * (3) StartRecording
   **************************/
  useEffect(() => {
    // Whenever initialSystemQuestionRef.current changes, update the state

    setInitialSystemQuestion(initialSystemQuestionRef.current);
  }, [initialSystemQuestionRef.current]);

  useEffect(() => {
    // console.log(
    //   "InitialSystemQuestionRef.current:",
    //   initialSystemQuestionRef.current
    // );
    if (!activityCountdownTimerComplete) {
      dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));
    }

    if (
      activityCountdownTimerComplete &&
      !systemSpeaking &&
      !initialQuestionAskedRef.current
    ) {
      // (1) Set system is speaking
      silenceUserAndEnableChatbot();

      // console.log(
      //   "SystemSpeakingRef.current:1",
      //   systemSpeaking,
      //   "initialSystemQuestionRef.current:",
      //   initialSystemQuestionRef.current,
      //   "activityCountdownTimerComplete:",
      //   activityCountdownTimerComplete
      // );
    } else if (
      activityCountdownTimerComplete &&
      systemSpeaking &&
      !initialQuestionAskedRef.current
    ) {
      // // 8/21/2024: Reset nextBtnClicked Reference for new scenario
      nextBtnClicked.current = false;
      // console.log(
      //   "SystemSpeakingRef.current:2",
      //   systemSpeaking,
      //   "initialSystemQuestionRef.current:",
      //   initialSystemQuestionRef.current,
      //   "activityCountdownTimerComplete:",
      //   activityCountdownTimerComplete
      // );

      // (2) Prep the Speaking soundwave, (3)Start recording
      handlePlayAudio(initialSystemQuestion);
    }
  }, [activityCountdownTimerComplete, systemSpeaking, initialSystemQuestion]);

  /*********************
   * Setup Animation to
   * Play the SystemAudio
   **********************/
  useEffect(() => {
    if (isRecording) {
      silenceChatbotAndEnableUser();
    }
  }, [isRecording]);
  /**************************************************************
   * Step 3: Once isRecording is true, start recording, and enable submit button
   * IMPORTANT: VOICE CAN ONLY BE DETECTED WHEN RECORDING
   * STARTED. THIS WILL CAUSE ChatActiveMicrophone TO DETECT SOUND
   * (1) Start Recording if isRecording is true
   * (2) Else Stop Recording if isRecording is false
   * (3) Reset userStoppedTalking
   ***************************************************************/
  useEffect(() => {
    if (isRecording && !currentScenario.complete && !allScenariosUploaded) {
      // (1): START RECORDING
      const startRecordingAsync = async () => {
        if (isRecording && !currentScenario.complete && !allScenariosUploaded) {
          // (1): START RECORDING
          await startRecording(
            isSystemAudioResponseRequested,
            initialSystemQuestionRef.current,
            "spoken",
            systemPromptRef.current,
            currentActivityType,
            chatTurnCounter,
            nextBtnClicked,
            questions
          );
        }
      };

      startRecordingAsync();

      /**Moved incrementTurnCounter to chatHistory useEffect**/
      // // (2) Enable submit button while talking
      // if (nextBtnDisabled && isTextMode) {
      //   dispatch(
      //     updateNextButtonStatus({ key: "nextBtnDisabled", value: false })
      //   );
      // }
    } else if (
      !isRecording &&
      // Removed because allScenariosuploaded is done before the last item, but isn't working with only 2 items
      !allScenariosUploaded
    ) {
      /*****************************************
       * (2) After detecting isRecording is  false,
       * Stop recording and reset userStoppedTalking
       *****************************************/
      const stopRecordingAsync = async () => {
        await stopRecording();
      };

      stopRecordingAsync();

      // (3) Reset userStoppedTalking
      setUserStoppedTalking(false);
    } else if (currentScenario.complete) {
      setSystemSpeaking(false);
    }
  }, [currentScenario, isRecording, systemSpeaking]);

  /***********************************
   * Step 4: Monitor userStoppedTalking
   * (1) If userStoppedTalking if
   * wordDetected is true or false
   * the recording must stop
   * and audio sent to processChatAudio
   ***********************************/
  useEffect(() => {
    // console.log(
    //   "User stopped Talking or wordDetected:",
    //   "wordDetected:",
    //   wordDetected,
    //   "wordDetectedRef:",
    //   wordDetectedRef,
    //   "isRecording:",
    //   isRecording,
    //   "userStoppedTalking:",
    //   userStoppedTalking
    // );
    if (isRecording && userStoppedTalking && wordDetected !== null) {
      //  if ((wordDetected === false && wordDetected !== wordDetectedRef.current) ||
      //   (wordDetected === true && wordDetected !== wordDetectedRef.current)) {
      //     wordDetectedRef.current = wordDetected;
      //   }
      // console.log("wordDetectedRef:", wordDetectedRef);
      setIsRecording(false);
    }
  }, [userStoppedTalking, wordDetected]);

  /****************************************
   * Step 5: When chatHistory has been received play
   * the assistants response, only if the currentScenario
   * is not complete.
   * (Loop: Request is made chatResponse is added to history)
   * (1) Find the Systems Response
   * (2) Play the System Response,
   * as long as button not clicked
   * (3) Increment the chatTurnCounter
   *****************************************/
  useEffect(() => {
    // chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    if (
      chatTurnCounter > 0 &&
      chatHistory.length > 0 &&
      !currentScenario.complete
    ) {
      // console.log(
      //   "CHATHISTORY CHANGED: chatHistory",
      //   chatHistory,
      //   "chatTurnCounter:",
      //   chatTurnCounter
      // );
      /*************************************
       * (1) Find the Assistant's Response, since we
       * prepended the inital response,  we want the
       * current response.
       * IMPORTANT: When chatHistory is updated,
       * we set isRecording to false, maybe we should
       * call SilenceUserAndEnableChatbot here.
       *************************************/
      const assistantResponse = findAssistantResponse(
        chatHistory,
        chatTurnCounter
      );
      // console.log(
      //   "Assistant Response:",
      //   assistantResponse,
      //   "ScenarioLoaded:",
      //   scenarioLoaded,
      //   "isRecording:",
      //   isRecording,
      //   "nextBtnClicked.current:",
      //   nextBtnClicked.current
      // );
      /*********************************************
       * (2A) Make sure not to play previous
       * audio if system is on a new scenario
       *********************************************/
      if (assistantResponse?.content && !nextBtnClicked.current) {
        systemSpeakingRef.current = true;
        setSystemSpeaking(true);
        systemSpeakingRef.current = true;
        // // (2) Stop Recording while system speaking
        // if (isRecording) {
        //   setIsRecording(false);
        // }
        // console.log(
        //   "ChatHistory:00",
        //   chatHistory,
        //   "currentScenari:",
        //   currentScenario
        // );
        // (2B) Play assistants response if available
        handlePlayAudio(assistantResponse.content);
        // (3) incrementTurnCounter
        incrementTurnCounter();
      }
    }
    // console.log(
    //   "ChatHistory:11",
    //   chatHistory,
    //   "activityCountdownTimerComplete:",
    //   activityCountdownTimerComplete,
    //   "nextBtnClicked.current:",
    //   nextBtnClicked.current,
    //   "currentScenari:",
    //   currentScenario
    // );
  }, [chatHistory, currentScenario.complete]);

  /***********************************
   * Step 6: When next button clicked
   * (1) Check if current scenario is complete
   * (2) Upload transcripts and audio to firebase
   ***********************************/
  useEffect(() => {
    const handleUpload = async () => {
      const transcriptionsToText = convertTranscriptionToText(chatHistory, 'text');
      // console.log("transcriptionsToText:", transcriptionsToText);
      // console.log("handleUpload: audioHistory:", audioHistory);
      const passageData = {
        transcripts: chatHistory,
        ...(audioHistory.length > 0 ? { audioBlob: audioHistory[0] } : {}),
        textBlob: transcriptionsToText,
      };

      // NOTE: We do not return actual passage data, just true/false for processChatAudio
      if (passageData) {
        const response = await uploadToFirebase({
          passageData,
          userId,
          setIsDataUploaded,
          typeNumber,
          ...(currentScenario.meta ? { meta: currentScenario.meta } : {}),
        });
        // console.log(response); // Optional: Handle the response if needed
      }
    };
    /**********************************/
    // (1) Check if scenario complete
    if (currentScenario.complete) {
      // console.log(
      //   "Scenario Complete",
      //   currentSourceRef,
      //   currentAudioContextRef
      // );

      // (2) Upload data to firebase
      handleUpload();
    } //if
  }, [currentScenario?.complete]);

  /************************************
   * Step 7: Set allScenariosUploaded after
   * last scenario data uploaded.
   * (1) Check if all scenario not uploaded
   * (2) If last scenario uploaded,
   * mark allScenariosUploaded to true
   * (3) Setup for next activity type
   ***********************************/
  useEffect(() => {
    // console.log(
    //   "!allScenariosUploaded:",
    //   allScenariosUploaded,
    //   "currentActivityTYpe:",
    //   currentActivityType,
    //   "currentLayout:",
    //   currentLayout,
    //   "allScenariosByActivityType:",
    //   allScenariosByActivityType,
    //   "isDataUploaded:",
    //   isDataUploaded,
    //   "currentStepperSegment:",
    //   currentStepperSegment,
    //   "activityTypeEndingSegment:",
    //   activityTypeEndingSegment
    // );
    // (1) Check if allScenariosUploaded is false
    if (!allScenariosUploaded && currentScenario.complete) {
      /***************************************
       * (2) After all scenarios have been uploaded for
       * activityType, and you are on the segment end page,
       * set all-scenarios-uploaded to true
       * This will execute on the next to the last scenario
       * so Footer can update on the last scenario
       * properly
       ****************************************/
      // console.log(
      //   "ShitedItem Complete allScenariosLength:",
      //   allScenariosByActivityType.length
      // );
      if (allScenariosByActivityType.length === 0 && isDataUploaded) {
        // console.log(
        //   "CONDITIONS MET: allScenariosUploaded:",
        //   allScenariosUploaded
        // );
        dispatch(
          // updateAdviceValue({ key: "all-scenarios-uploaded", value: true })
          updateKeyByActivity({
            activityType: currentActivityType,
            key: "all-scenarios-uploaded",
            value: true,
          })
        );
        // (3) Setup for next activity type
        setupNextScenario();
      }
    } //if
  }, [isDataUploaded, currentScenario, allScenariosByActivityType]);

  /***************************************************
   * Keep nextBtnClicked in sync with submitBtnClicked
   ***************************************************/
  useEffect(() => {
    setSubmitBtnClicked(nextBtnClicked.current);
    // console.log(
    //   "submitBtnClicked:",
    //   submitBtnClicked,
    //   "nextBtnClicked:",
    //   nextBtnClicked.current
    // );
    if (submitBtnClicked !== nextBtnClicked.current) {
      // console.log("wordDetectedRef:", wordDetectedRef);
      setSubmitBtnClicked(nextBtnClicked.current);
    }
    // console.log(
    //   "submitBtnClicked:",
    //   submitBtnClicked,
    //   "nextBtnClicked:",
    //   nextBtnClicked.current
    // );
  }, [nextBtnClicked.current]);

  /*****************************
   * Disable the nextt button
   * while user is speaking
   * we can only accept a complete
   * chat turn (which consists of
   * the chatbot response)
   *****************************/
  useEffect(() => {
    if (type === "voice" && startedTalking) {
      dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));
    }
  }, [isTalkingForFiveSeconds, startedTalking, type]);

  useEffect(() => {
    const userStoppedTalking = type === "silent" && prevType === "voice";
    // console.log(
    //   "SILENT: isSilentForFiveSeconds:",
    //   isSilentForFiveSeconds,
    //   "isTalkingForFiveSeconds",
    //   isTalkingForFiveSeconds,
    //   "Type:",
    //   type,
    //   "prevType:",
    //   prevType,
    //   "isRecording:",
    //   isRecording,
    //   "isDataUploaded:",
    //   isDataUploaded,
    //   "systemSpeaking:",
    //   systemSpeaking,
    //   "userStoppedTalking:",
    //   userStoppedTalking
    // );
    // console.log("MIC Type:", type, "PREV TYPE:", prevType)
    // if (type === "voice" && !submitBtnClicked) {
    if (type === "voice") {
      // console.log(
      //   "Setting userStoppedTalking to false",
      //   ":SubmitButtonClicked:",
      //   submitBtnClicked
      // );
      setUserStoppedTalking(false); // Reset the state when it turns voice
      userStoppedTalkingRef.current = false;
      // } else if (type === "silent" && userStoppedTalking && !submitBtnClicked) {
    } else if (type === "silent" && userStoppedTalking) {
      // console.log(
      //   "Setting userStoppedTalking to true",
      //   ":SubmitButtonClicked:",
      //   submitBtnClicked
      // );
      setUserStoppedTalking(true); // Set the state when it turns silent after voice
      userStoppedTalkingRef.current = true;
    }
    // else if (submitBtnClicked) {
    //   console.log(
    //     "ChatActiveMicrophone: Submit Button Clicked: ButtonClicke:",
    //     submitBtnClicked,
    //     " Reseting state"
    //   );
    //   userStoppedTalkingRef.current = false;
    // }
  }, [type, toggle]);

  /*******************************************/
  //   Handle the returned data
  /*********************************************/
  return (
    <div className="opinion-container">
      <div className="details-container">
        <div className="description-wrapper">
          <span className="title">{currentScenario?.title}</span>
          <TextComponent text={description} />
        </div>
        {/* // HIDING THIS ELEMENT */}
        <div className="chatbot-wrapper">
          {currentLayout === "opinion-instruction-review" &&
            !activityCountdownTimerComplete ? (
            <div className="counters-and-microphone">
              <CountDownTimer
                isTimerComplete={activityCountdownTimerComplete}
                setIsTimerComplete={setActivityCountdownTimerComplete}
                duration={countdownDuration}
                durationUnit={countdownUnit}
                disableCircularProgressbar={false}
                setCurrentCountdownTime={setCurrentCountdownTime}
                classLabel="hide"
              />
              {countdownDuration && typeof countdownDuration === "number" ? (
                <CountDownBar
                  countdownDuration={60}
                  countdownUnit={"seconds"}
                  timeLeft={currentCountdownTime}
                  overrideActivityCounter={setActivityCountdownTimerComplete}
                />
              ) : null}
            </div>
          ) : (
            /******************************************
             * REPLACED DISAPPEARREPPEAR CLOCK
             * See Readme
             ******************************************/

            <div className="microphone-container">
              <ChatHistoryAudio
                activityCoundownTimerComplete={activityCountdownTimerComplete}
                isDataUploaded={isDataUploaded}
                prompt={prompt}
                isSilentForFiveSeconds={isSilentForFiveSeconds}
                isTalkingForFiveSeconds={isTalkingForFiveSeconds}
                startedTalking={startedTalking}
                toggle={toggle}
                type={type}
                prevType={prevType}
                processText={processText}
                audioPlayed={audioPlayed}
                audioPlayedRef={audioPlayedRef}
                systemSpeaking={systemSpeaking}
                systemSpeakingRef={systemSpeakingRef}
                setAudioPlayed={setAudioPlayed}
                userStoppedTalking={userStoppedTalking}
                setUserStoppedTalking={setUserStoppedTalking}
                isRecording={isRecording}
                submitBtnClicked={nextBtnClicked.current}
              />
            </div>
          )}
        </div>
      </div>
      {/* <div className="image-container">
        <RowContainer
          currentScenario={currentScenario}
          originalLabel={originalLabel}
          newLabel={newLabel}
        />
      </div> */}
      <div className="image-container">{displayImages()}</div>
    </div>
  );
};

export default WithRecording(OpinionInstructionReview);
